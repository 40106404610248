'use strict';

angular.module('windmanagerApp')
  .directive('addBlade', function() {
    return {
      templateUrl: 'components/directives/blade/addBlade/addBlade.html',
      restrict: 'EA',
      scope: {
        turbine: '=',
        link: '='
      },
      controller: 'AddEditBladeCtrl',
      controllerAs: 'addBlade',
      link: function(scope, element, attrs) {
      }
    };
  });
